@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "assets/css/font-lauis.css";
@import "assets/css/ionicons.min.css";
@import "assets/css/linea-font.css";
@import "assets/scss/style";

.fade-text {
    transition: opacity 0.5s;
    opacity: 1;
}

.fade-enter {
opacity: 0.01;
}

.fade-enter.fade-enter-active {
opacity: 1;
transition: opacity 500ms ease-in;
}

.fade-leave {
opacity: 1;
}

.fade-leave.fade-leave-active {
opacity: 0.01;
transition: opacity 300ms ease-in;
}